var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"tabindex":"100"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.onArrowUpPress.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.onArrowDownPress.apply(null, arguments)}]}},[_c('v-card',{staticClass:"card-size"},[_c('v-card-title',[_c('h2',{staticClass:"headline mb-0",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('v-row',{attrs:{"justify":"start","align":"center"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"id":"usuario-tabela-input-search","append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),(_vm.canAccessCRUD)?_c('div',{staticClass:"mt-1 ml-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","id":"usuario-tabela-button-config"},on:{"click":_vm.abrirConfiguracao}},on),[_c('v-icon',[_vm._v("settings")])],1)]}}],null,false,2320596986)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.configuracao', 1)))])])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.registros,"options":_vm.pagination,"item-class":_vm.onItemClass,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[(_vm.canAccessCRUD || _vm.isUsuarioExternoMaster)?_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.editar')))])],1):_vm._e(),(!_vm.canAccessCRUD)?_c('v-list-item',{on:{"click":function($event){return _vm.ver(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("dehaze")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.visualizar')))])],1):_vm._e(),(_vm.canAccessCRUD || _vm.isUsuarioExternoMaster)?_c('v-list-item',{on:{"click":function($event){return _vm.redefinirSenha(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("lock")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.redefinir_senha')))])],1):_vm._e()],1)],1)]}},{key:"item.nome_sobrenome",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomeSobrenome)+" ")]}},{key:"item.nom_usuario",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomUsuario)+" ")]}},{key:"item.nom_perfil",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomPerfil)+" ")]}},(_vm.isUsuarioFuncionario)?{key:"item.nom_estrutura_usuario",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomEstruturaUsuario)+" ")]}}:null,(_vm.isUsuarioFuncionario)?{key:"item.divisoes",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNomesDivisoesSeparadosPorVirgula(item.divisoes))+" ")]}}:null,(_vm.implantacao.indVarejo)?{key:"item.grupofornecedor",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.montaTexto(item.grupofornecedor))+" ")]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }